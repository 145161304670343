$font-primary: 'Latin Modern Roman', Serif;

/*
$nav-icon-width: 40px;
$nav-icon-height: 36px;
*/

@function strip-px($number) {
  @return $number / 1px;
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

$nav-icon-bar-height: 2px;
$nav-icon-bar-margin-top:10px;

$nav-icon-margin: 20px;
$nav-icon-height: $nav-icon-bar-height*3 + $nav-icon-bar-margin-top*2;
$nav-icon-height-unitless: strip-px($nav-icon-height - $nav-icon-bar-height);

$nav-icon-bar-width: #{sqrt($nav-icon-height-unitless*$nav-icon-height-unitless + $nav-icon-height-unitless*$nav-icon-height-unitless)}px;

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1024px) { @content ; }
  }
  @else if $point == tablet-and-up {
    @media (min-width: 769px)  { @content ; }
  }
  @else if $point == tablet-and-down {
    @media (max-width: 1024px)  { @content ; }
  }
  @else if $point == mobile {
    @media (max-width: 768px)  { @content ; }
  }
}