@import "variables";

p, h1, h2, h3, h4, h5, h6, h7 {
  color:white;
}

main {
  p, ul, ol {
    font-size:1.2em;
  }

  input {
    font-size:1em;
  }

  ul {
    list-style:none;
    padding:0;
    margin:0;
    padding-left: 1em;

    li {
      &:before {
        display:inline-block;
        content: '–';
        width:1em;
        margin-left:-1em;
      }
    }
  }
}

p {
  font-family: $font-primary;
  font-size:1.2em;

  &.special-character::first-letter {
    font-size:3em;
    line-height: 0.98em;
    float: left;
    padding-top: 0px;
    padding-right: 8px;
  }
}

h1 {
  font-family: 'Latin Modern Roman Caps';
  font-size:6em;
  margin-top:400px;
  margin-bottom:0px;

  text-shadow: 2px 2px 16px #222;

  @include breakpoint(tablet-and-down) {
    margin-top:100px;
    font-size:4em;
  }
}

h2 {
  font-weight: bold;
  margin-top:0px;
  margin-left:100px;
  font-size:2em;
  margin-bottom:100px;

  text-shadow: 2px 2px 16px #222;

  @include breakpoint(tablet-and-down) {
    margin-left:0px;
    font-size:1.6em;
    margin-bottom:60px;
  }
}

h3 {
  font-size:2em;
  font-style: italic;
  font-weight: bold;
}

h4 {
  font-size:1.2em;
}

a {
  text-decoration: none;
}