.parallax-background {
  background-image: url('http://placekitten.com/1000/1000');
  background-position: 50% 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  //-moz-transform: translate3d(0,0,0);
  //-webkit-transform: translate3d(0,0,0);
  //transform: translate3d(0,0,0);
}

#nav-icon {
  width: $nav-icon-bar-width;
  height: $nav-icon-height;
  position: fixed;
  z-index:9;
  top:0;
  right:0;
  margin: $nav-icon-margin;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: $nav-icon-bar-height;
  width: 100%;
  background: #fff;
  border-radius: $nav-icon-bar-height/2;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

  //box-shadow: 2px 2px 10px #222;
}

#nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: #{$nav-icon-bar-height + $nav-icon-bar-margin-top};
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: #{$nav-icon-bar-height*2+$nav-icon-bar-margin-top*2};
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  //left: 8px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: #{$nav-icon-bar-height*2+$nav-icon-bar-margin-top*2};
  //left: 8px;
}

#nav-icon.open + nav {
  display:block;
}