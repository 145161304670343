@import "variables";
@import "fonts";
@import "typography";
@import "utils";

// catch IE11 as outdated
@media all and (-ms-high-contrast: none) {
  #outdated{
    display: block;
  }
}

html {
  min-height:100vh;
}

body {
  margin:0;
  padding: 0;
  font-family: $font-primary;

  &.startpage {
    min-height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    -ms-flex-pack: center;

    .wrapper {
      padding-bottom:0px !important;

      main {
        padding:0px !important;
      }
    }
  }

  .img-popup {
    position:fixed;
    z-index: 10;
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);

    img {
      max-width:90%;
      max-height:90%;
    }


  }
}

#outdated * {
  font-family:sans-serif;
}

.parallax-window {
  z-index:1;
}

.parallax-background {
  background-size:cover;
}

.lang {
  list-style-type: none;
  margin: $nav-icon-margin;
  padding:0;
  position:fixed;
  top:0;
  left:0;

  li {

    a {
      &:not(.custom-color) {
        color:white;
      }
      text-shadow: 2px 2px 16px #222;
      text-decoration: none;
      font-family: 'Latin Modern Roman Caps';
      font-size:1.6em;
    }

    span {
      width:40px;
      height:30px;
      opacity:0.7;
      transition: opacity 0.3s;

      &:hover {
        opacity:1;
      }
    }
  }
}

nav {
  display:none;
  position:fixed;
  top: #{$nav-icon-margin*2+$nav-icon-bar-height*3+$nav-icon-bar-margin-top*2};
  right:$nav-icon-margin;
  z-index:9;

  -webkit-box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);
  -moz-box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);
  box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);

  @include breakpoint(tablet-and-down) {
    width: calc(100% - #{$nav-icon-margin*2});
  }

  ul {
    list-style-type: none;
    margin:0;
    padding:20px 30px;
    background-color:white;

    li {
      font-size:1.2em;
      margin: 4px 0px;

      @include breakpoint(tablet-and-down) {
        font-size:1.4em;
      }

      a {
        text-decoration: none;
        color:black;

        &.active {
          //text-decoration:underline;
        }
      }
    }
  }
}

// startpage
.startpage-svg{
  padding-top:50px;
  fill:white;
  width:400px;
  height:auto;

  @include breakpoint(desktop) {
    width:520px;
  }

  @include breakpoint(mobile) {
    width:80%;
  }
}

.startpage-text {
  font-family: 'Latin Modern Roman Caps';
  text-align: center;
  font-size:1.6em;
  padding:20px 0px;
  margin-bottom:0px;
  padding-bottom:0px;

  text-shadow: 2px 2px 16px #222;


  &::before, &::after {
    display:block;
    content: "";
    border-top:1px solid white;
    width: 4rem;
    margin: 20px auto;

    text-shadow: 2px 2px 16px #222;

    //transform: translateY(-1rem);
  }
}

.notification-container {
  position:fixed;
  z-index:10;
  right:0;
  width:360px;
  height:100%;
  padding:20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  pointer-events: none;
  display:flex;
  flex-direction: column-reverse;

  @include breakpoint(mobile) {
    width:100%;
  }

  .notification {
    padding:14px 20px;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #543cff;
    color:white;
    margin-top:10px;
    font-size:1.2em;
    -webkit-box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);
    -moz-box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);
    box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);

    &.success {
      background-color: #188647;
    }

    &.error {
      background-color:darkred;
    }
  }
}



.wrapper {
  padding-bottom:100px;
  display:flex;
  flex-direction: column;
  align-items: center;
  min-height:100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include breakpoint(mobile) {
    padding-bottom:40px;
  }

  main {
    width:900px;
    min-height:100%;

    @include breakpoint(tablet-and-down) {
      width: 100%;
      padding: 20px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    section {
      padding:0px 100px;
      box-sizing: border-box;
      margin-bottom:60px;
      color:white;

      @include breakpoint(tablet-and-down) {
        padding:0px;
      }
    }

    .band-horizontal {
      display:flex;
      justify-content: space-around;

      @include breakpoint(mobile) {
        flex-wrap: wrap;
      }

      .member {
        flex:0 0 200px;
        text-align:center;
        padding-bottom:40px;

        &:nth-child(even) {
          padding-top:50px;

          @include breakpoint(mobile) {
            padding-top:0px;
          }
        }

        img.portrait {
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin-bottom:6px;
          cursor:pointer;
        }

        p {
          margin:0;
        }

        p.member-name {
          font-size:1.3em;
        }

        p.member-instruments {
          font-size:1em;
        }
      }
    }

    .products-horizontal, .events-horizontal, .youtube-horizontal {
      display:flex;
      flex-direction: column;

      .product {
        display:flex;
        border-bottom:1px dashed white;
        padding-top:20px;

        @include breakpoint(mobile) {
          flex-direction:column;
        }

        .product-image {
          flex:0 1 190px;
          padding:10px 0px;

          img {
            width:190px;
          }
        }

        .product-content {
          flex:1 1 auto;
          padding:10px 0px 10px 30px;

          .product-description {
            /*
            @include breakpoint(mobile) {
              display:none;
            }
            */
          }

          .product-samples-toggle {
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

            p:before {
              font-family: 'FontAwesome';
              content: '\f196';
              margin-right:10px;
              font-size:0.8em;
            }

            &.open p:before {
              content: '\f147';
            }
          }

          .product-samples {
            height:0;
            overflow:hidden;

            &.open {
              //height: auto;
            }
          }

          @include breakpoint(mobile) {
            padding-left:0px;
          }

          .product-title {
            font-size:1.8em;
            font-style:italic;
            font-weight: bold;
            margin-top:0px;
            margin-bottom:4px;
          }

          .product-subtitle {
            margin-top:2px;
            font-size:1em;
          }

          .product-shop {
            display:flex;
            justify-content: space-between;

            @include breakpoint(mobile) {
              flex-direction:column;
            }

            .product-price {
              font-weight: bold;
              font-family: 'Latin Modern Roman Caps';
            }

            .product-buy {
              //font-weight: bold;
              position:relative;

              -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
              -khtml-user-select: none; /* Konqueror HTML */
              -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;

              .shop-amount, .shop-plus, .shop-minus {
                color:black;
                background-color: white;
                position:absolute;
                z-index:9;

                font-size: 12px;
                line-height: 14px;
                width:14px;
                height:14px;
                text-align: center;
                padding:2px;
                font-family:sans-serif;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top:6px;

                -webkit-transition: all 0.25s;
                -moz-transition: all 0.25s;
                -ms-transition: all 0.25s;
                -o-transition: all 0.25s;
                transition: all 0.25s;

                cursor:default;
              }

              .shop-minus, .shop-plus {
                cursor:pointer;
                font-size:14px;
                line-height: 14px;
              }

              .shop-plus {
                @include breakpoint(tablet-and-up) {
                  left:-24px;
                }
                @include breakpoint(mobile) {
                  right: 24px;
                }
              }

              .shop-minus {
                @include breakpoint(tablet-and-up) {
                  left: -72px;
                }
                @include breakpoint(mobile) {
                  right: 72px;
                }
              }

              .shop-amount {
                @include breakpoint(tablet-and-up) {
                  left: -48px;
                }
                background-color:#222222;
                color:white;
                @include breakpoint(mobile) {
                  right: 48px;
                }
              }
            }
          }
        }
      }

      .event {
        border-bottom:1px dashed white;
        padding-top:20px;

        .event-title {
          font-size:1.8em;
          font-style:italic;
          margin-top:0px;
          margin-bottom:4px;
        }

        .event-location {
          margin-top:2px;
          font-weight: bold;
        }

        a {
          text-decoration:none;
        }
      }

      .youtube-video {
        margin-bottom:30px;

        iframe {
          max-width:100%;
        }

        .youtube-title {
          font-size:1.8em;
          font-style:italic;
          font-weight: bold;
          margin-top:0px;
          margin-bottom:4px;
        }

        .youtube-subtitle {
          margin-top:2px;
          font-size:1.6em;
          font-style:italic;
        }
      }
    }


  }
}

form {
  margin-top:30px;

  .form-item {
    font-size:1.2em;
    display:flex;
    margin: 6px 0px;

    @include breakpoint(mobile) {
      flex-direction:column;
    }

    label {
      flex:0 0 220px;
      @include breakpoint(mobile) {
        flex-basis: auto;
      }
    }

    input {
      flex:1 0 auto;

      //font-size:1em;
      font-family:$font-primary;
      border:none;
      padding:4px;

      &:not(.submit) {
        outline:none;
        background:none;
        border-bottom:1px solid white;
        color:white;
      }

      &.submit {
        margin-top:12px;
        font-size:1em;
        cursor: pointer;
        opacity:0.75;
        -webkit-transition: all 0.25s;
        -moz-transition: all 0.25s;
        -ms-transition: all 0.25s;
        -o-transition: all 0.25s;
        transition: all 0.25s;

        &:hover {
          opacity:1;
        }

        &:disabled {
          background-color:#aaaaaa;
          color:black;
        }
      }

      &[data-invalid=true] {
        border-color:red;
      }

    }
  }
}

.music-player {
  position:relative;
  display:flex;
  padding:12px;
  cursor:pointer;
  background-color:rgba(255, 255, 255, 1);
  color:black;
  margin-bottom:12px;
  align-items: center;
  box-shadow: 2px 2px 16px rgba(32, 32, 32, 0.6);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .progress {
    z-index:0;
    position:absolute;
    width:0%;
    height:100%;
    opacity:0.4;
    left:0;
    pointer-events: none;

    -webkit-transition: all 0.05s;
    -moz-transition: all 0.05s;
    -ms-transition: all 0.05s;
    -o-transition: all 0.05s;
    transition: all 0.05s;
  }

  .info {
    z-index:1;
    flex:1 1 auto;
    font-size:1em;
  }

  .action-buttons {
    z-index:1;
    flex:0 1 60px;
    text-align:right;
    font-size:1.2em;

    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;

    .fa-play, .fa-pause, .fa-stop {
      cursor: pointer;

      &:hover {
        opacity:0.7;
      }
    }
  }
}