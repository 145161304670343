@font-face {
  font-family: 'Latin Modern Roman';
  src: url('/assets/fonts/latin-modern-roman/lmr-bolditalic-webfont.woff2') format('woff2'),
  url('/assets/fonts/latin-modern-roman/lmr-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Latin Modern Roman Caps';
  src: url('/assets/fonts/latin-modern-roman/lmrcaps-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/latin-modern-roman/lmrcaps-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Latin Modern Roman';
  src: url('/assets/fonts/latin-modern-roman/lmr-italic-webfont.woff2') format('woff2'),
  url('/assets/fonts/latin-modern-roman/lmr-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Latin Modern Roman';
  src: url('/assets/fonts/latin-modern-roman/lmr-regular-webfont.woff2') format('woff2'),
  url('/assets/fonts/latin-modern-roman/lmr-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}